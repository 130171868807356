/**
 * FileUpload component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Canceler } from 'axios';

import { CropperProps } from 'react-mobile-cropper';

import React, { ChangeEvent, cloneElement, ReactElement, useEffect, useRef, useState } from 'react';

import { observer, SaveStoreKeys, SavingStore } from '@/store/core/saving';

import { If, Notify } from '@/cutils';
import { FileTypeByMime, FileUtil, Http, Url } from '@/utils';
import { FileInfo, setupFilesInfo, useI18n, usePhotoEditor } from '@/hooks/core';

import { FileExtension } from '@/shared/types';
import { UploadService } from '@/services/Upload/Upload';

import { StorageFileEntity, StorageImageSize, StorageSpace } from '@/codegen/graphql';

import { Avatar, Progress, Spinner } from '@exode.ru/vkui';
import { Icon56CancelCircleOutline } from '@vkontakte/icons';

import { FileRowProps } from '@/components/Atoms/FileRow';

import { FileInfoPart } from './parts/FileInfoPart';
import { PreviewDocPart } from './parts/PreviewDocPart';
import { CancelUploadPart } from './parts/CancelUploadPart';
import { PreviewVideoPart } from './parts/PreviewVideoPart';
import { UploadButtonPart } from './parts/UploadButtonPart';
import { FileUploadVideoPart } from './parts/FileUploadVideoPart';

import { FileUploadVideoMode } from './interfaces';

import { Container } from './FileUpload.styled';


export interface FileUploadProps {
    id: string;
    multiple: boolean;
    height: string;
    width: string;
    space: StorageSpace;
    children?: ReactElement;
    addIconSize?: number;
    closeIconSize?: number;
    accept?: FileExtension;
    previewVideo?: string;
    className?: string;
    showInfo?: boolean;
    editImageBeforeUpload?: boolean;
    cropperProps?: CropperProps;
    /** Reset to original state after uploading to the server */
    shouldResetFiles?: boolean;
    ignoreUploadCancelTrigger?: boolean;
    videoModes?: FileUploadVideoMode[];
    onFileUpload?: (value: StorageFileEntity[] | Pick<StorageFileEntity, 'id' | 'location' | 'meta'>[]) => void;
    getPreviewDoc?: (fileInfo: FileInfo | undefined) => FileUploadProps['previewDoc'];
    previewDoc?: {
        src?: string;
        fileRowProps?: FileRowProps;
    };
    previewImage?: {
        src?: string;
        objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
    };
}

interface FirstFileState {
    type: string;
    file: File;
}


const FileUpload = observer((props: FileUploadProps) => {

    const {
        id,
        children,
        space,
        multiple,
        previewImage,
        previewVideo,
        onFileUpload,
        cropperProps,
        className = '',
        showInfo = true,
        closeIconSize = 56,
        shouldResetFiles = true,
        accept = FileExtension.All,
        editImageBeforeUpload = false,
        ignoreUploadCancelTrigger = false,
        ...rest
    } = props;

    const { t } = useI18n('components.Atoms.FileUpload');

    const { openPhotoEdit } = usePhotoEditor();

    const [ files, setFiles ] = useState<FileList | null>(null);
    const [ filesInfo, setFilesInfo ] = useState<FileInfo[] | null>(null);
    const [ firstFileState, setFirstFileState ] = useState<FirstFileState | null>(null);

    const [ loadedImagePreview, setLoadedImagePreview ] = useState('');

    const [ videoLinkInput, setVideoLinkInput ] = useState(
        Url.videoUrlIsThirdParty(previewVideo)
            ? previewVideo!
            : '',
    );

    const [ uploadProgress, setUploadProgress ] = useState(0);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const cancelFileUploadRef = useRef<Canceler | null>(null);

    const isUploading = SavingStore.itemIsSavingRecord(SaveStoreKeys.Uploading, id);

    /** Can be provided as static or via function */
    const previewDoc = props.previewDoc || props.getPreviewDoc?.(filesInfo?.[0]);

    const setSavingStore = (loading: boolean) => (
        SavingStore.setSavingRecord(SaveStoreKeys.Uploading, id, loading)
    );

    /** Set new files state */
    const setFilesState = (files: FileList) => {
        setFiles(files);
        setFirstFileState({ type: files[0].type, file: files[0] });
    };

    /** Handle file upload */
    const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        SavingStore.setSavingRecord(SaveStoreKeys.Uploading, id, true);

        const { files } = e.target;

        e.preventDefault();
        e.stopPropagation();

        if (files && files.length > 10) {
            return Notify.toast(t('max10FilesCanBeUploaded'));
        }

        if (files && !multiple && editImageBeforeUpload) {
            /** Can be edited only image type of file */
            if (FileUtil.getFileTypeByMimeType(files[0].type) === FileTypeByMime.Image) {
                return uploadAfterEdit(files[0]);
            }
        }

        if (files && files?.length) {
            setFilesState(files);
        }
    };

    /** Handle photo editor file upload */
    const uploadAfterEdit = (file: File) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onloadend = ({ target }) => {
            openPhotoEdit(
                { ...cropperProps, src: target?.result as string },
                (editorFile) => setFilesState(FileUtil.createFileList(editorFile, file.name)),
                () => clearAndCancelUploading(),
            );

            /** Cancel the file reading operation */
            fileReader.abort();
        };
    };

    const loadPreview = () => {
        const { type, file } = firstFileState || {};

        const fileReader = new FileReader();

        if (type?.includes('image')) {
            file && fileReader.readAsDataURL(file);

            fileReader.onloadend = () => (
                setLoadedImagePreview(fileReader.result as string)
            );
        }
    };

    const resetInputValue = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement;

        if (input.value) {
            input.value = '';
        }
    };

    const uploadToServer = async (filesInfo: FileInfo[]) => {
        setUploadProgress(1);

        const formData = new FormData();

        formData.append('space', space);
        Http.appendFormData(formData, 'meta', filesInfo);

        _.forEach(files, (file) => formData.append('files', file));

        const { success, exception, payload } = await UploadService.uploadFiles(
            id,
            formData,
            cancelFileUploadRef,
            setUploadProgress,
            [ StorageImageSize.Medium, StorageImageSize.Small ],
        );

        if (!success && (exception || exception !== 'no-files')) {
            Notify.toast.error(exception || t('loadWasCanceled'));
        }

        if (success) {
            onFileUpload?.(payload);
        }

        setUploadProgress(0);

        clearAfterAnyUploadResult(shouldResetFiles);
    };

    const clearAfterAnyUploadResult = (reset: boolean) => {
        setSavingStore(false);

        if (reset) {
            setFiles(null);
            setFirstFileState(null);
            setFilesInfo(null);
        }
    };

    const clearAndCancelUploading = () => {
        clearAfterAnyUploadResult(true);

        setVideoLinkInput('');
        setLoadedImagePreview('');

        if (!ignoreUploadCancelTrigger) {
            onFileUpload?.([ { id: 0, location: null, meta: { size: 0, duration: 0 } } ]);
        }

        if (cancelFileUploadRef.current) {
            cancelFileUploadRef.current(t('fileLoadWasStopped'));
        }
    };

    /** Listen changed files (deps should be only [ files ]) */
    useEffect(() => {
        if (files) {
            setupFilesInfo(files)
                .then(async (filesInfo) => {
                    setFilesInfo(filesInfo);

                    await uploadToServer(filesInfo);
                }).catch((error) => console.log(error));
        }

        return () => {
            if (cancelFileUploadRef.current) {
                cancelFileUploadRef.current(t('fileLoadWasStopped'));
            }

            setSavingStore(false);
        };
    }, [ files ]);

    useEffect(() => {
        if (firstFileState?.file) {
            loadPreview();
        }
    }, [ firstFileState ]);

    return (
        <Container previewDoc={previewDoc}
                   previewImage={previewImage}
                   previewVideo={previewVideo}
                   data-test={`file-upload.${id}`}
                   hideInputLabel={!!previewVideo}
                   className={[
                       className,
                       'relative overflow-hidden rounded-[16px]',
                       (!children && !previewDoc?.src) && 'no-children',
                   ].join(' ')}
                   {...rest}>
            <If is={!children}>
                {/** Cancel uploading */}
                <If is={!!uploadProgress}>
                    <CancelUploadPart clearAndCancelUploading={clearAndCancelUploading}/>
                </If>

                {/** Video */}
                <>
                    {/** Upload video */}
                    <If is={accept === FileExtension.Video}>
                        <FileUploadVideoPart previewVideo={previewVideo}
                                             onFileUpload={onFileUpload}
                                             videoLinkInput={videoLinkInput}
                                             setVideoLinkInput={setVideoLinkInput}
                                             clearAndCancelUploading={clearAndCancelUploading}
                                             uploadProgress={uploadProgress} {...props}/>
                    </If>

                    {/** Preview video */}
                    <If is={!!videoLinkInput || !!previewVideo}>
                        <PreviewVideoPart linkInput={videoLinkInput}
                                          previewVideo={previewVideo}
                                          onFileChange={onFileUpload}/>
                    </If>
                </>

                {/** Image */}
                <>
                    {/** Preview image */}
                    <If is={(
                        !!previewImage?.src
                        || (!!loadedImagePreview && !!firstFileState?.type.includes('image'))
                    )}>
                        <Avatar mode="app"
                                shadow={false}
                                overlayMode="dark"
                                onClick={clearAndCancelUploading}
                                src={previewImage?.src || loadedImagePreview}
                                className={previewImage?.src ? 'pointer-events-none preview' : 'preview'}
                                overlayIcon={<Icon56CancelCircleOutline width={closeIconSize}
                                                                        height={closeIconSize}/>}/>
                    </If>
                </>

                {/** Preview (docx, pdf, etc.) files */}
                <If is={(
                    !isUploading && !!previewDoc?.src
                    || (
                        !isUploading
                        && !!firstFileState?.file
                        && !firstFileState?.type.includes('image')
                        && !firstFileState?.type.includes('video')
                    )
                )}>
                    <PreviewDocPart previewDoc={previewDoc}
                                    clearAndCancelUploading={clearAndCancelUploading} {...props}/>
                </If>
            </If>

            {/** Button to open file manager (start upload) */}
            <label htmlFor={id} className={!children ? 'file-upload top-0 absolute' : ''}>
                <If is={!children}>
                    <UploadButtonPart filesInfo={filesInfo}
                                      isUploading={isUploading}
                                      uploadProgress={uploadProgress} {...props}/>
                </If>

                <If is={!!children}>
                    <>
                        {children && cloneElement(children, {
                            isUploading,
                            uploadProgress,
                            disabled: isUploading,
                            loading: isUploading,
                            progress: uploadProgress,
                            onClick: () => inputRef.current?.click(),
                        })}
                    </>
                </If>
            </label>

            {/** File html input element */}
            <input id={id}
                   type="file"
                   ref={inputRef}
                   accept={accept}
                   multiple={multiple}
                   onClick={resetInputValue}
                   onChange={onFileInputChange}/>

            <If is={!children}>
                {/** Progress line */}
                <If is={isUploading || uploadProgress > 0 && uploadProgress < 100}>
                    <div className="absolute bottom-0 w-full">
                        <Progress value={uploadProgress}
                                  aria-labelledby="progressLabel"
                                  className="justify-center items-center h-[15px]"
                                  permanentChildren={(
                                      <If is={uploadProgress <= 10}>
                                          <span className={[
                                              'flex justify-center items-center',
                                              'absolute fs-10 top-0 left-0 font-bold w-full h-full',
                                          ].join(' ')}>
                                              {Math.min(uploadProgress, 99)}%
                                          </span>
                                      </If>
                                  )}>
                            <If is={uploadProgress > 10}>
                                <div className="flex h-full fs-10 text-white justify-center items-center">
                                    <span className="font-bold">{Math.min(uploadProgress, 99)}%</span>
                                </div>
                            </If>
                        </Progress>
                    </div>
                </If>

                {/** Loading spinner */}
                <If is={isUploading}>
                    <Spinner size="medium" className="absolute z-[1] top-0"/>
                </If>

                {/** File info */}
                <If is={showInfo && accept !== FileExtension.Video}>
                    <FileInfoPart files={files} filesInfo={filesInfo}/>
                </If>
            </If>
        </Container>
    );
});


export { FileUpload };
