/**
 * School types
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';

import { GqlResult } from '@/types/graphql';
import { SchoolContactRole, SchoolFindOneQueryResult } from '@/codegen/graphql';


export type SchoolItem = GqlResult<SchoolFindOneQueryResult>['schoolFindOne'];


export const schoolContactRole = () => {

    const { t } = useI18n('types.school');

    return {
        [SchoolContactRole.Support]: t('support'),
        [SchoolContactRole.Manager]: t('manager'),
        [SchoolContactRole.Director]: t('director'),
    } as const;
};
