/**
 * CourseCard component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { PageParams } from 'router.tsx';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { CourseManageFragmentFragment } from '@/codegen/graphql';

import { RoutePathType } from '@/router/paths';

import { Avatar, ButtonGroup, Card, Text } from '@exode.ru/vkui';
import { Icon28GiftOutline, Icon48PictureOutline } from '@vkontakte/icons';

import { useLocation } from '@/router/index';
import { CourseFindManyItem } from '@/types/course';
import { Router as RouterService } from '@/services/Utils/Router';

import { If } from '@/cutils';

import { TagsItem } from './parts/TagsItem';
import { PriceItem } from './parts/PriceItem';
import { BgIconPart } from './parts/BgIconPart';
import { ProgressItem } from './parts/ProgressItem';
import { CartFooterItem } from './parts/CartFooterItem';
import { CommonFooterItem } from './parts/CommonFooterItem';
import { ManageFooterItem } from './parts/ManageFooterItem';

import { getCourseCartColors } from './helper';
import { Container } from './CourseCard.styled';


export interface CourseCardProps {
    course: CourseFindManyItem;
    mode?: 'default' | 'cart' | 'progress' | 'manage';
    onClickMode?: 'default' | 'currentLesson';
    className?: string;
    vkCardClassName?: string;
    pickRouteParams?: string[];
    colorful?: boolean;
}


const CourseCard = observer((props: CourseCardProps) => {

    const {
        colorful,
        className,
        vkCardClassName,
        mode = 'default',
        pickRouteParams = [],
        onClickMode = 'default',
        course: {
            id,
            name,
            image,
            product,
            currentLesson,
        },
    } = props;

    const { route: { params: routeParams } } = useLocation();

    const handleClick = () => {
        const path: RoutePathType = mode === 'manage'
            ? '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)'
            : (() => {
                switch (onClickMode) {
                    case 'currentLesson':
                        return '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)';

                    default:
                        return '/courses/:page([0-9]+)/:courseId([0-9]+)';
                }
            })();

        const params: PageParams = _.omitBy({
            ..._.pick(routeParams, pickRouteParams),
            page: '1',
            courseId: `${id}`,
            lessonId: onClickMode === 'currentLesson'
                ? `${currentLesson?.id}`
                : '',
        }, _.isEmpty);

        RouterService.pushPage(path, params);
    };

    return (
        <Container {...props} onClick={handleClick} className={[
            className,
            'vk-rounded cursor-pointer relative overflow-hidden',
        ].join(' ')}>
            <Card mode="outline" data-test={`course.card.${id}`} data-test-static="course.card" className={[
                'h-full',
                vkCardClassName,
                !colorful ? '' : 'before:content-none',
                colorful && getCourseCartColors(id, PreferenceStore.isDark),
            ].join(' ')} style={{
                background: (
                    !PreferenceStore.isDark
                    && mode === 'default'
                    && product.isFree && colorful
                )
                    ? 'var(--background_hover)'
                    : '',
            }}>
                <div className={[
                    'flex vk-rounded flex-col h-full',
                    !colorful ? 'thin-border' : '',
                ].join(' ')}>
                    <div className="section-image">
                        <TagsItem course={props.course}/>

                        <Avatar mode="app" src={image.main} children={(
                            image.main
                                ? undefined
                                : (
                                    <Icon48PictureOutline className={colorful ? 'opacity-70' : ''}
                                                          fill={colorful ? 'var(--text_primary)' : undefined}/>
                                )
                        )} className={[
                            'course-image self-start cursor-pointer vk-rounded is-compact m:self-start',
                        ].join(' ')}/>
                    </div>

                    <div className={[
                        'flex justify-between flex-col py-5 m:py-4 w-full h-full',
                    ].join(' ')}>
                        <div className={[
                            'section-text cursor-pointer mb-0.5 px-5 m:px-4 is-compact',
                        ].join(' ')}>
                            <div className={[
                                'flex justify-between mt-0.5 flex-col pt-8',
                            ].join(' ')}>
                                <Text weight="medium"
                                      data-test="course.name"
                                      style={{ lineHeight: '120%' }}
                                      className={[
                                          'line-clamp-2 fs-20',
                                      ].join(' ')}>
                                    {name}
                                </Text>
                            </div>
                        </div>

                        <If is={mode === 'progress'}>
                            <div className="px-5 m:px-4">
                                <ProgressItem course={props.course}/>
                            </div>
                        </If>

                        <If is={[ 'default', 'cart', 'manage' ].includes(mode)}>
                            <div className={[
                                'flex items-center justify-between mt-8 px-5 m:px-4 flex-col',
                            ].join(' ')}>
                                <ButtonGroup gap="m" className={[
                                    'items-center w-full justify-between',
                                ].join(' ')}>
                                    <If is={![ 'cart', 'manage' ].includes(mode)}>
                                        <CommonFooterItem mode={mode} course={props.course}/>

                                        <PriceItem product={product}/>
                                    </If>

                                    <If is={mode === 'cart'}>
                                        <CartFooterItem course={props.course}/>
                                    </If>

                                    <If is={mode === 'manage'}>
                                        <ManageFooterItem course={props.course as CourseManageFragmentFragment}/>
                                    </If>
                                </ButtonGroup>
                            </div>
                        </If>
                    </div>
                </div>
            </Card>

            <If is={mode === 'default' && product.isFree && !!colorful}>
                <BgIconPart icon={(
                    <Icon28GiftOutline width={250} height={250} style={{
                        opacity: 0.4,
                        marginRight: -30,
                        transform: 'rotate(20deg)',
                    }}/>
                )}/>
            </If>
        </Container>
    );
});


export { CourseCard };
