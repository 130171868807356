/**
 * Page
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement, ReactNode, useEffect, useLayoutEffect } from 'react';

import { RefetchQueriesInclude, useApolloClient } from '@apollo/client';

import { RouterStore } from '@/store/core/router';
import { SellerStore } from '@/store/user/seller';
import { ConfigStore, observer } from '@/store/core/config';
import { PreferenceStore } from '@/store/preference/preference';
import { PageStore, PageStore as PageCoreStore, PageStructureModeType } from '@/store/core/page';

import { useLocation } from '@/router/index';
import { useI18n } from '@/hooks/core/useI18n';
import { RoutePathType } from '@/router/paths';
import { AppColorSchemeType } from '@/shared/types';

import { Breadcrumbs, Skeleton } from '@mui/material';
import { If, Link, Notify, PageUtil, Popout, PopoutRoot, Portal, Responsive, ScrollSaver } from '@/cutils';

import { Icon20ChevronRightOutline, Icon24RefreshOutline, Icon28ArrowLeftOutline } from '@vkontakte/icons';

import {
    Caption,
    Group,
    GroupProps,
    IconButton,
    PanelHeaderButton,
    PullToRefresh,
    Spinner,
    SpinnerProps,
    Text,
    Title,
} from '@exode.ru/vkui';

import { PageAction } from '@/services/Utils/Router';
import { PanelHeaderPage } from '@/components/Panel/Header';


interface PageWrapperProps {
    /** Scroll save params from route state */
    params?: string[];
    /** Transparent top part */
    transparent?: boolean;
    /** With shadow top part */
    withShadow?: boolean;
    /** Page mode */
    mode?: PageStructureModeType;
    /** Page children */
    children?: ReactElement | ReactElement[];
    /** Remove vk ui safe bottom space */
    removeBottomSpace?: boolean;
    /** Remove all safe spaces (e.g. onboarding etc.) */
    entireScreen?: boolean;
    /** Change theme forced */
    forceAppScheme?: AppColorSchemeType;
}

export interface PageBreadcrumbsProps {
    breadcrumbs: {
        title: ReactNode;
        path?: PageAction;
        openSection?: string;
    }[];
}

interface PageHeadProps {
    children?: any;
}

interface PageHeaderProps {
    title: ReactNode;
    mode?: 'm' | 'd';
    backTo?: RoutePathType | null | string;
    left?: ReactElement;
    content?: ReactElement;
    right?: ReactElement;
    actions?: ReactElement | ReactElement[];
    refetchDocs?: RefetchQueriesInclude;
}

interface PagePopoutsProps {
    children: ReactElement | ReactElement[];
}

interface PageContextMenuProps {
    children: ReactElement | ReactElement[];
    contextIsScrollable?: boolean;
}

interface PageContentProps {
    d?: string;
    m?: string;
    className?: string;
    refresh?: boolean;
    children?: any;
    withTabbar?: boolean;
    withoutHeader?: boolean;
}

interface PageRowProps extends GroupProps {
    d?: string;
    m?: string;
    className?: string;
    children?: any;
    mobileSeparator?: GroupProps['separator'];
    fullHeight?: boolean;
    fullSize?: boolean;
    isFirst?: boolean;
    isDisabled?: boolean;
    isSquare?: boolean;
    pageLine?: 'all' | 'd' | 'm';
    mobileModalNoMx?: boolean;
    innerNoVerticalPadding?: boolean | 0;
    innerNoHorizontalPadding?: boolean | 0;
    borderRadius?: ('bottom-none' | 'top-none')[];
}

interface PageSectionProps {
    children: ReactElement | ReactElement[] | ReactNode;
    className?: string;
    paddings?: string;
    indent?: string;
    ref?: React.Ref<any>;
    horizontalHalfPadding?: boolean;
}

interface PageContextGapWrapperProps {
    children: ReactElement | ReactElement[];
}

interface PageOnModeProps {
    onModes?: PageStructureModeType[];
    offModes?: PageStructureModeType[];
    children: ReactElement | ReactElement[];
}

export interface PageHeaderActionsProps {
    title: ReactNode;
    subtitle?: ReactNode;
    titleClassName?: string;
    subtitleClassName?: string;
    caption?: ReactNode;
    actions?: ReactNode;
    bottom?: ReactNode;
    children?: ReactNode;
    icon?: ReactNode;
    pageRowProps?: PageRowProps;
}

/**
 * Wrapper of the page
 * @param {PageWrapperProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const PageWrapper = (props: PageWrapperProps) => {

    const {
        mode,
        transparent,
        forceAppScheme,
        withShadow = true,
    } = props;

    const { route: { params } } = useLocation();

    const keys = _.pick(RouterStore.params, props.params || []);
    const scrollKey = RouterStore.pageId + ':' + JSON.stringify(keys);

    const scrollDeps = _.values(_.pick(params, props.params || []));

    PageCoreStore.setPageModeStructure(mode || 'regular');

    if (!_.isUndefined(transparent)) {
        PageCoreStore.setPanelHeader({ transparent });
    }

    useEffect(() => {
        PageCoreStore.setPanelHeader({ withShadow });

        if (forceAppScheme) {
            PreferenceStore.setScheme(forceAppScheme, false).then();

            PreferenceStore.merge({ toggleIsDisabled: true });
        }

        if (!withShadow) {
            document.querySelector('body')?.style.setProperty(
                '--background_page',
                'var(--background_content)',
            );
        }

        return () => {
            PageCoreStore.setPanelHeader({
                transparent: false,
                withShadow: true,
                visor: true,
            });

            if (forceAppScheme) {
                const { scheme } = PreferenceStore.getSavedOrSystemScheme();

                PreferenceStore.setScheme(scheme, false).then();

                PreferenceStore.merge({ toggleIsDisabled: false });
            }

            document.querySelector('body')?.style.removeProperty('--background_page');
        };
    }, []);

    return (
        <>
            <ScrollSaver scrollKey={scrollKey} scrollDeps={scrollDeps}/>

            {props.children}

            <If is={!!props.removeBottomSpace}>
                <PageUtil.RemoveBottomSafeSpace entireScreen={props.entireScreen}/>
            </If>
        </>
    );
};

/**
 * Head of the page
 * @param {PageHeadProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const PageHead = (props: PageHeadProps) => {
    return (
        <>
            {props.children}
        </>
    );
};

/**
 * Page header content wrapper
 * @type {(props: PageContentProps) => JSX.Element}
 */
const PageHeaderContentWrapper = observer((props: PageContentProps) => {

    const { t } = useI18n('pages.Core');

    return (
        <>
            <If is={!ConfigStore.disconnected}>
                {props.children}
            </If>

            <If is={ConfigStore.disconnected}>
                <div className="flex flex-row gap-2 items-center w-fit">
                    <Spinner size="small"/>
                    <span>{t('connecting')}</span>
                </div>
            </If>
        </>
    );
});

const PageHeader = (props: PageHeaderProps) => {

    const { t } = useI18n('pages.Core');

    const apolloClient = useApolloClient();
    const { route: { pageId } } = useLocation();

    const { title, left, content, right, mode, actions, refetchDocs, backTo = '/menu' } = props;

    const back = backTo !== null && (
        <Link backOrTo={backTo || undefined}>
            <PanelHeaderButton>
                <Icon28ArrowLeftOutline/>
            </PanelHeaderButton>
        </Link>
    );

    const refreshDocs = async () => {
        const id = `page-refetch:${pageId}`;

        Notify.toast.loading(t('updating'), { id });

        try {
            await apolloClient.refetchQueries({
                include: refetchDocs,
            });
        } catch (e) {}

        Notify.toast.success(t('successfullyUpdated'), { id, duration: 1100 });
    };

    const render = (
        <>
            <PanelHeaderPage.Left>
                {left || back || <></>}
            </PanelHeaderPage.Left>

            <PanelHeaderPage.Content wrap={!content} aside={(
                <>
                    {actions}

                    <If is={!!refetchDocs?.length}>
                        <Responsive.Desktop>
                            <IconButton data-test="page-refetch" className="ml-2 m-auto" onClick={refreshDocs}>
                                <Icon24RefreshOutline fill="var(--accent)"/>
                            </IconButton>
                        </Responsive.Desktop>
                    </If>
                </>
            )}>
                <Page.HeaderContentWrapper>
                    {content || <>{title}</>}
                </Page.HeaderContentWrapper>
            </PanelHeaderPage.Content>

            <PanelHeaderPage.Right>
                {right || <></>}
            </PanelHeaderPage.Right>
        </>
    );

    return !mode
        ? render
        : (mode === 'm'
                ? <Responsive.Mobile>{render}</Responsive.Mobile>
                : <Responsive.Desktop>{render}</Responsive.Desktop>
        );
};

const PagePopouts = (props: PagePopoutsProps) => {
    return (
        <PopoutRoot>
            {props.children || <Popout id="example" withDarkness>Example</Popout>}
        </PopoutRoot>
    );
};

const PageSpinner = (
    props: SpinnerProps & {
        mode?: 'spinner' | 'skeleton';
        wrapperClassName?: string;
        skeleton?: ReactElement;
    },
) => {
    const { wrapperClassName, mode = 'spinner' } = props;

    const skeleton = props.skeleton || (
        <div className="h-[60px] min-h-full p-2">
            <Skeleton height="calc(100% - 16px)" variant="rectangular" className="vk-rounded"/>
        </div>
    );

    return (
        <div className={[
            wrapperClassName,
            'm-auto p-10 vk-rounded bg-content thin-border',
        ].join(' ')}>
            <If is={mode === 'spinner'}>
                <Spinner size="medium" {...props} className={[
                    'm-auto h-fit',
                    props.className,
                ].join(' ')}/>
            </If>

            <If is={mode === 'skeleton'}>
                <>{skeleton}</>
            </If>
        </div>
    );
};

/**
 * Right context menu on desktop view
 * @returns {JSX.Element}
 * @constructor
 */
const PageContextMenu = (props: PageContextMenuProps) => {

    useLayoutEffect(() => {
        if (props.contextIsScrollable) {
            PageCoreStore.setContextModeType('scrollable');
        }

        return () => PageCoreStore.setContextModeType('regular');
    }, []);

    return (
        <Portal id="menu:context">
            <>{props.children}</>
        </Portal>
    );
};

/**
 * Page section
 * @param {PageSectionProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const PageSection = (props: PageSectionProps) => {

    const { indent, horizontalHalfPadding, paddings = 'px-6 m:px-3' } = props;

    const className = `${!horizontalHalfPadding ? paddings : 'px-4'} ${props.className}` + (indent ? ` p-${indent}` : '');

    return (
        <section className={className} ref={props.ref}>
            {props.children}
        </section>
    );
};

/**
 * Wrapper for page content
 * @param {PageContentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const PageContent = observer((props: PageContentProps) => {

    const {
        children,
        withoutHeader,
        refresh,
        withTabbar,
        d = '',
        m = '',
        className = '',
    } = props;

    const classes = [
        className,
        withoutHeader ? 'without-header' : '',
        'page-wrapper full-screen py-0 fillable',
        (withTabbar || RouterStore.type === 'tab')
        && RouterStore.forceTabbar !== 'hide'
            ? 'with-tabbar'
            : 'without-tabbar',
    ].join(' ');

    const content = (
        <Responsive.Classier d={d} m={m}>
            <div className={classes}>
                {children}
            </div>
        </Responsive.Classier>
    );

    return refresh
        ? <PullToRefresh onRefresh={() => ({})} isFetching={false} children={content}/>
        : content;
});

/**
 * Item of page context
 * @type {(props: PageRowProps) => JSX.Element}
 */
const PageRow = observer((props: PageRowProps) => {

    const { isDesktop } = ConfigStore;

    const {
        children,
        isFirst,
        isSquare,
        pageLine,
        fullSize,
        isDisabled,
        fullHeight,
        borderRadius,
        mobileSeparator,
        mobileModalNoMx,
        innerNoVerticalPadding,
        innerNoHorizontalPadding,
        d = '',
        m = '',
    } = props;

    const separator = !isDesktop && mobileSeparator || props.separator;

    const hasPageLine = pageLine === 'all'
        || pageLine === 'm' && !isDesktop
        || pageLine === 'd' && isDesktop;

    const className = [
        props.className,
        isFirst ? 'vkui-group--first' : '',
        hasPageLine ? 'page-line' : '',
        fullHeight ? 'page-row--full' : '',
        fullSize ? 'page-row--full-height' : '',
        isSquare ? 'rounded-t-none rounded-b-none card-clear-after' : '',
        innerNoVerticalPadding ? 'inner-no-vertical-padding' : '',
        innerNoHorizontalPadding ? 'inner-no-horizontal-padding' : '',
        innerNoVerticalPadding === 0 ? 'inner-no-vertical-padding-0' : '',
        innerNoHorizontalPadding === 0 ? 'inner-no-horizontal-padding-0' : '',
        mobileModalNoMx ? 'm:-mx-4' : '',
        borderRadius?.includes('top-none') ? 'rounded-t-none' : '',
        borderRadius?.includes('bottom-none') ? 'rounded-b-none' : '',
    ].join(' ');

    const omit = [
        'mobileSeparator',
        'fullHeight',
        'fullSize',
        'isFirst',
        'pageLine',
    ];

    if (isDisabled) {
        return children;
    }

    return (
        <Responsive.Classier d={d} m={m}>
            <Group {..._.omit(props, omit)} separator={separator} className={className}>
                {children}
            </Group>
        </Responsive.Classier>
    );
});

/**
 * Page group separator
 * @returns {JSX.Element}
 * @constructor
 */
const PageGroupSeparator = () => {
    return (
        <div className="vkuiGroup__separator Group__separator vkuiSpacing Spacing"
             aria-hidden="true"
             style={{ height: 16 }}/>
    );
};

/**
 * Context wrapper
 * @param {PageContextGapWrapperProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const PageContextWrapper = (props: PageContextGapWrapperProps) => {
    return (
        <div className="flex flex-col gap-4 mb-4">
            {props.children}
        </div>
    );
};

/**
 * Renders breadcrumb component for a page.
 * @type {({breadcrumbs}: PageBreadcrumbsProps) => JSX.Element}
 */
const PageBreadcrumbs = observer(({ breadcrumbs }: PageBreadcrumbsProps) => {

    const items = breadcrumbs.map(({ path, title, openSection }, i) => {
        if (path) {
            return (
                <Link key={i} pushPage={path}>
                    <Text className="text-accent">{title}</Text>
                </Link>
            );
        }

        if (openSection) {
            return (
                <Text key={i}
                      className="text-accent cursor-pointer"
                      onClick={() => SellerStore.openSection(openSection)}>
                    {title}
                </Text>
            );
        }

        return <Text key={i} className="text-primary">{title}</Text>;
    });

    return (
        <Page.Row mobileSeparator="hide" className="m-thin-border-bottom z-10">
            <Page.Section indent="2" className="px-4">
                <Breadcrumbs separator={<Icon20ChevronRightOutline fill="var(--text_subhead)" width={16} height={16}/>}
                             aria-label="breadcrumb">
                    {items}
                </Breadcrumbs>
            </Page.Section>
        </Page.Row>
    );
});

/**
 * Page mode
 * @param {} props
 * @returns {JSX.Element}
 * @constructor
 */
const PageOnMode = (props: PageOnModeProps) => {

    const { onModes, offModes, children } = props;

    const conditions = [
        !onModes?.length || onModes.includes(PageStore.mode),
        !offModes?.length || !offModes.includes(PageStore.mode),
    ];

    return (
        <If is={conditions.filter(e => e).length === conditions.length}>
            {children}
        </If>
    );
};

/**
 * Represents the main header of a page.
 * @param {PageHeaderActionsProps} props
 * @returns {JSX.Element}
 * @constructor
 */
const PageMainHeader = (props: PageHeaderActionsProps) => {

    const {
        title,
        subtitle,
        caption,
        titleClassName,
        subtitleClassName,
        pageRowProps,
        actions,
        icon,
        children,
        bottom = children,
    } = props;

    return (
        <Page.Row className="overflow-hidden" {...pageRowProps}>
            <Page.Section className="relative" indent="4">
                <Title weight="3" level="1" className={[
                    titleClassName,
                    'first-letter:capitalize',
                ].join(' ')}>
                    {title}
                </Title>

                <If is={!!subtitle}>
                    <Text weight="regular" className={[
                        'mt-2 first-letter:capitalize',
                        subtitleClassName,
                    ].join(' ')}>
                        {subtitle}
                    </Text>
                </If>

                <If is={!!caption}>
                    <Caption className="mt-2 text-secondary">
                        {caption}
                    </Caption>
                </If>

                <If is={!!actions}>
                    <div className="mt-5 flex gap-4">
                        {actions}
                    </div>
                </If>

                <>{icon}</>

                <If is={!!bottom}>
                    <div className="mt-5 pb-1">
                        {bottom}
                    </div>
                </If>
            </Page.Section>
        </Page.Row>
    );
};


const Page = {
    Wrapper: PageWrapper,
    Head: PageHead,
    Header: PageHeader,
    Popouts: PagePopouts,
    Spinner: PageSpinner,
    Content: PageContent,
    Section: PageSection,
    Row: PageRow,
    GroupSeparator: PageGroupSeparator,
    Context: PageContextMenu,
    ContextWrapper: PageContextWrapper,
    Breadcrumbs: PageBreadcrumbs,
    HeaderContentWrapper: PageHeaderContentWrapper,
    OnMode: PageOnMode,
    MainHeader: PageMainHeader,
};


export { Page };
