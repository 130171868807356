/**
 * SubjectSearchSelect
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { useI18n } from '@/hooks/core';

import { EducationSubject, FilterSubjectInput, useSubjectFindManyLazyQuery } from '@/codegen/graphql';

import { SearchSelect, SearchSelectProps, SelectSearchOption } from '@/components/Atoms/SearchSelect';


interface Props {
    onSelect: (params: SelectSearchOption) => void;
    filter?: FilterSubjectInput;
    selectedSubjects?: EducationSubject[];
    select?: Partial<SearchSelectProps>;
}


const SubjectSearchSelect = (props: Props) => {

    const { onSelect, filter, select, selectedSubjects } = props;

    const { t } = useI18n('components.Subject.SubjectSearchSelect');

    const [ search, { data, loading } ] = useSubjectFindManyLazyQuery({
        variables: {
            list: {},
            filter: { ...filter },
        },
        onError: error => console.error(error),
    });

    const options = data?.subjectFindMany.items
        ?.filter(({ visible }) => visible)
        .map(({ name, subject }) => ({
            value: subject,
            label: name,
        }));

    const searchCallback = (value: string) => search({
        variables: {
            list: { take: 100, skip: 0 },
            filter: { ...filter, search: value },
        },
    });

    useEffect(() => {
        filter?.contentCategoryIds && searchCallback('');
    }, [ JSON.stringify(filter?.contentCategoryIds) ]);

    return (
        <SearchSelect {...select} loading={loading}
                      onSelect={onSelect}
                      options={options || []}
                      dataTest="course.subject"
                      selectedIds={selectedSubjects}
                      searchCallback={searchCallback}
                      placeholder={t('searchBySubject')}/>
    );
};


export { SubjectSearchSelect };
