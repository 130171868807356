/**
 * InvoiceProductPayoutPart
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { SellerBalanceOperationItem } from '@/types/seller';
import { invoiceProductPayoutStatusName } from '@/types/payout';
import { InvoiceProductPayoutStatus, ProductCurrency, SellerBalanceSource } from '@/codegen/graphql';

import { Group, Header, InfoRow, Separator, SimpleCell } from '@exode.ru/vkui';

import { AmountText } from '@/components/Atoms/AmountText';


interface Props {
    source: SellerBalanceSource;
    currency: ProductCurrency | undefined | null;
    invoiceProductPayouts: SellerBalanceOperationItem['invoiceProductPayouts'];
}


const InvoiceProductPayoutPart = (props: Props) => {

    const { source, currency, invoiceProductPayouts } = props;

    const invoiceProductPayoutStatusNames = invoiceProductPayoutStatusName();

    const { t } = useI18n('components.Seller.Balance.OperationDetails');

    const sourceIsRefund = source === SellerBalanceSource.BalanceDownRefund;

    return (
        <>
            <Header className="pb-0">
                {t('payoutDetails', { length: invoiceProductPayouts.length })}
            </Header>

            <Group className="pt-0">
                {invoiceProductPayouts.map((payout, index) => {
                    const {
                        id,
                        status,
                        commission,
                        profitAmount,
                        invoiceProduct,
                        commissionAmount,
                        payoutScheduledAt,
                        transactionAmount,
                    } = payout;

                    const isRefunded = status === InvoiceProductPayoutStatus.Refunded;

                    return (
                        <div key={index}>
                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('payoutId')}>
                                    #{id}
                                    {' • '}
                                    <Link pushPage={{
                                        id: '/manage/course/:courseId([0-9]+)/information',
                                        params: { courseId: `${invoiceProduct.product.course?.id}` },
                                    }}>
                                        <span className="text-accent">
                                            {invoiceProduct.product.course?.name}
                                        </span>
                                    </Link>
                                </InfoRow>
                            </SimpleCell>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow data-value={status}
                                         header={t('operationStatus')}
                                         data-test="balance.operation-status">
                                    {invoiceProductPayoutStatusNames[status]}
                                </InfoRow>
                            </SimpleCell>

                            <If is={status === InvoiceProductPayoutStatus.Waiting}>
                                <SimpleCell hasHover={false} hasActive={false}>
                                    <InfoRow header={t('transferToPayoutBalanceWillBeMade')}>
                                        {moment(payoutScheduledAt).format('D MMMM YYYY в HH:mm')}
                                    </InfoRow>
                                </SimpleCell>
                            </If>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={(
                                    sourceIsRefund && isRefunded ? t('refundAmount') : t('profitAmount')
                                )}>
                                    <AmountText toFixed={2}
                                                semibold={false}
                                                currency={currency}
                                                amount={profitAmount}
                                                className={[
                                                    !sourceIsRefund && isRefunded ? 'line-through' : '',
                                                ].join(' ')}/>
                                </InfoRow>
                            </SimpleCell>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('platformCommission')}>
                                    <div className="flex gap-1">
                                        <AmountText toFixed={2}
                                                    semibold={false}
                                                    currency={currency}
                                                    amount={commissionAmount}
                                                    dataTest="balance.operation.platform-commission"
                                                    className={[
                                                        sourceIsRefund && isRefunded ? 'line-through' : '',
                                                    ].join(' ')}/>

                                        <span>•</span>

                                        <span className="text-secondary">
                                            {commission.percent}%,
                                            {' '}
                                            {t('from', { from: moment(commission.startAt).format('D MMMM YYYY') })}
                                        </span>
                                    </div>
                                </InfoRow>
                            </SimpleCell>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('bankCommission')}>
                                    <AmountText toFixed={2}
                                                semibold={false}
                                                currency={currency}
                                                amount={transactionAmount}
                                                dataTest="balance.operation.bank-commission"
                                                className={[
                                                    sourceIsRefund && isRefunded ? 'line-through' : '',
                                                ].join(' ')}/>
                                </InfoRow>
                            </SimpleCell>

                            <If is={index !== invoiceProductPayouts.length - 1 && invoiceProductPayouts.length > 1}>
                                <Separator className="px-4 my-3"/>
                            </If>
                        </div>
                    );
                })}
            </Group>
        </>
    );
};


export { InvoiceProductPayoutPart };
