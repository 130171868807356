/**
 * FooterLegalPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/core/config';
import { PlatformStore } from '@/store/platform';

import { Link } from '@/cutils';

import { Caption } from '@exode.ru/vkui';


const FooterLegalPart = observer(() => {

    const { COMMON, legalDocs } = PlatformStore;

    return (
        <div className="flex m:flex-col flex-wrap gap-5 mb-3 mt-1 d:pr-11 pb-safe-1">
            <Link pushPage={{ id: '/about' }}>
                <Caption className="text-secondary flex items-center">
                    <span className="fs-15 pt-0.5 mr-1">©</span>
                    <span>
                        {COMMON?.organization.info.name}
                        {' '}
                        {new Date().getFullYear()}
                    </span>
                </Caption>
            </Link>

            {legalDocs?.list?.map(({ name, location }, index) => (
                <Link key={index} blank toOuter={location}>
                    <Caption className="text-secondary">
                        {name}
                    </Caption>
                </Link>
            ))}
        </div>
    );
});


export { FooterLegalPart };
