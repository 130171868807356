/**
 * CourseCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';

import { CourseCardProps } from './CourseCard';


export const CourseList = styled.div<{ desktopRowGap?: string }>`
    display: flex;
    flex-direction: column;
    row-gap: var(--formitem_padding, 16px);

    ${responsive([ 'desktop' ])} {
        row-gap: ${({ desktopRowGap }) => desktopRowGap};
    }
`;

export const CourseGrid = styled.div`
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));

    ${responsive([ 'laptop' ])} {
        gap: var(--vkui--size_field_horizontal_padding--regular);
        grid-template-columns: repeat(2, minmax(0, calc(100% / 2)));
    }

    ${responsive([ 'tablet' ])} {
        gap: var(--vkui--size_field_horizontal_padding--regular);
        grid-template-columns: minmax(0, 100%);
    }
`;

export const Container = styled.div<CourseCardProps>`
    position: relative;
    max-width: 100%;
    background-color: var(--modal_card_background);

    .progress-list {
        display: grid;
        margin-top: 0.75rem;
        grid-gap: ${({ course: { lessons } }) => lessons.length < 10 ? `2px` : '0'};
        grid-template-columns: ${({ course: { lessons } }) => `repeat(${lessons?.length}, minmax(0, 1fr))`};
    }

    .section-image {
        position: relative;

        .vkuiAvatar--shadow:after {
            z-index: 11;
        }

        .tags-wrapper {
            position: absolute;
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            z-index: 2;

            .tag-item {
                display: flex;
                min-height: 18px;
                align-items: center;
                justify-content: flex-start;
                border-radius: 50px;
            }
        }

        .glass-effect {
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
        }

    }

    .section-text {
        min-height: 82px;
        padding-right: calc(64px + 1.25rem * 2);

        ${responsive([ 'lt-tablet' ])} {
            padding-right: calc(64px + 1rem * 2);
        }
    }

    ${responsive([ 'mobile', 'lt-tablet' ])} {
        margin-left: var(--vkui--size_field_horizontal_padding--regular);
        margin-right: var(--vkui--size_field_horizontal_padding--regular);
    }

    .vkuiCard {
        border-radius: var(--vkui--size_border_radius_paper--regular);

        .tag {
            min-height: 22px;
            min-width: unset;

            .vkuiButton__content {
                padding: 0 0.5rem;
            }

            [data-is-windows="true"] {
                .vkuiButton__in {
                    align-items: flex-start;
                }
            }
        }
    }

    .Card__in {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .course-image {
            aspect-ratio: 16/9;
            border-radius: var(--vkui--size_border_radius_paper--regular) !important;

            ${responsive([ 'gte-tablet' ])} {
                min-height: 120px;
                height: calc(100% - 1.25rem) !important;
            }

            ${responsive([ 'lt-tablet' ])} {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            &.is-compact {
                position: absolute;
                top: 1.25rem;
                right: 1.25rem;
                min-height: initial;
                width: 64px !important;
                height: 64px !important;
                border-radius: 8px !important;

                ${responsive([ 'tablet' ])} {
                    top: 1rem;
                    right: 1rem;
                }
            }
        }
    }
`;
