/**
 * SearchSelect component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ChangeEvent } from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Icon20PictureOutline } from '@vkontakte/icons';
import { Avatar, CustomSelect, CustomSelectOption } from '@exode.ru/vkui';

import { UserAvatar } from '@/components/Atoms/UserAvatar';


export interface SelectSearchOption {
    value: string | number;
    label: string;
    avatar?: string;
    optional?: {
        [key: string | number]: any
    };
}

export interface SearchSelectStateProps {
    loading?: boolean;
    disabled?: boolean;
}


export interface SearchSelectProps extends SearchSelectStateProps {
    onSelect: (value: SelectSearchOption) => void;
    searchCallback: (value: string) => void;
    options: SelectSearchOption[];
    clearOnSelect?: boolean;
    avatarType?: 'user' | 'icon';
    selectedIds?: string[] | number[];
    emptyText?: string;
    dataTest?: string;
    placeholder?: string;
    avatarMode?: 'default' | 'image' | 'app';
}


const SearchSelect = (props: SearchSelectProps) => {

    const { t } = useI18n('components.Atoms.SearchSelect');

    const {
        options,
        loading,
        onSelect,
        disabled,
        dataTest,
        avatarType,
        selectedIds,
        searchCallback,
        clearOnSelect = false,
        avatarMode = 'default',
        placeholder = t('enter'),
        emptyText = t('searchDidGiveAnyResults'),
    } = props;

    const debouncedCallback = _.debounce((callback) => callback(), 500);

    const handleChange = (option: SelectSearchOption) => {
        onSelect(option);

        clearOnSelect && searchCallback('');
    };

    const handleInput = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;

        debouncedCallback(() => searchCallback(value));
    };

    return (
        <CustomSelect searchable
                      filterFn={false}
                      options={options || []}
                      fetching={loading}
                      disabled={disabled}
                      data-test={dataTest}
                      onInput={handleInput}
                      emptyText={emptyText}
                      value={selectedIds?.[0]}
                      placeholder={placeholder}
                      onChange={(event) => {
                          const value = event.target.value;
                          const option = _.find(options, { value }) || _.find(options, { value: +value });

                          option && handleChange(option);
                      }}
                      renderOption={({ option, ...restProps }) => (
                          <CustomSelectOption {...restProps} selected={selectedIds?.includes(option.value as never)}
                                              before={avatarType && (
                                                  <>
                                                      <If is={avatarType === 'user'}>
                                                          <UserAvatar shadow
                                                                      size={24}
                                                                      mode={avatarMode}
                                                                      name={option.label}
                                                                      src={option.avatar}
                                                                      userId={option.value}
                                                                      showUserOnlineBadge={false}/>
                                                      </If>

                                                      <If is={avatarType === 'icon'}>
                                                          <Avatar mode={avatarMode}
                                                                  shadow
                                                                  size={24}
                                                                  src={option.avatar}>
                                                              {!option.avatar &&
                                                                  <Icon20PictureOutline width={16} height={16}/>}
                                                          </Avatar>
                                                      </If>
                                                  </>
                                              )}>
                              {option.label}
                          </CustomSelectOption>
                      )}/>
    );
};


export { SearchSelect };
