/**
 * DesktopManageServicesList styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { bgContent } from '@/styles/modules/background';


export const Container = styled.div`
    top: 0;
    right: 0;
    position: fixed;
    overflow: hidden;
    z-index: 9999;

    .wrapper {
        height: 100vh;
        width: 90px;
        overflow: hidden;
        overflow-y: auto;
    }

    &.is-closed {
        .wrapper {
            ${bgContent(0.7)};
            backdrop-filter: blur(7px);
            -webkit-backdrop-filter: blur(7px);
        }
    }

    &.is-opened {
        .wrapper {
            ${bgContent(1)};
        }
    }

    @media (max-width: 1390px) {
        right: -90px;
        transition: right .3s ease;
        transition-delay: .1s;

        &:after {
            content: '';
            display: flex;
            position: fixed;
            top: 0;
            height: 100%;
            width: calc(30px + 70px);
            margin-left: -30px;
            transition: width .2s;
            transition-delay: .2s;
        }

        &:hover {
            right: 0;
            transition: right .25s ease;
            transition-delay: 0s;

            &:after {
                width: calc(30px);
            }
        }
    }

    .SimpleCell {
        border-radius: 0;
    }
`;

export const MenuItem = styled.div`
    .SimpleCell {
        height: 68px;
        padding-left: 4px;
        padding-right: 4px;
    }
`;
