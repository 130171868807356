/**
 * TournamentMembersModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { Page } from '@/pages/Core/Page';

import { TournamentMembersQuery } from '@/codegen/graphql';

import { Title } from '@exode.ru/vkui';
import { Graphql, Modal } from '@/cutils';
import { MembersListView } from '@/modals/Tornament/Members/views/MembersListView';

import { tournamentMembersListConfig } from '@/pages/Tournament/Lobby/constants';


interface Props {
    id: string;
}


const TournamentMembersModal = (props: Props) => {

    const [ { tournamentId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">Участники турнира</Title>}>
            <Modal.Body isScrollable fullHeight>
                <TournamentMembersQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ tournamentLobbyFindManyMembers }) => (
                                <MembersListView {...tournamentLobbyFindManyMembers} />
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{ ...tournamentMembersListConfig, tournamentId: +tournamentId }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentMembersModal };
