/**
 * LanguageSwitcher
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';

import { ConfigStore } from '@/store/core/config';

import { Avatar } from '@exode.ru/vkui';
import { Icon28GlobeOutline } from '@vkontakte/icons';

import { IconByLang } from '@/images/countries';

import { HeaderItem } from '@/components/Desktop/Panel';

import { Container } from './LanguageSwitcher.styled';


interface Props {
    component?: Function;
    componentProps?: Record<any, any>;
}


const LanguageSwitcher = observer((props: Props) => {

    const {
        componentProps = {},
        component: Component = HeaderItem,
    } = props;

    const LanguageIcon = IconByLang[ConfigStore.language];

    return (
        <Container>
            <Component className={[
                'bg-transparent',
                !ConfigStore.isDesktop && 'vk-rounded',
            ].join(' ')} children={(
                <Avatar size={35} shadow={false} badge={(
                    <Avatar size={16} shadow={false} className="bg-transparent">
                        <LanguageIcon width={15} height={15}/>
                    </Avatar>
                )}>
                    <Icon28GlobeOutline fill="var(--accent)"/>
                </Avatar>
            )} {...componentProps}/>
        </Container>
    );
});


export { LanguageSwitcher };
