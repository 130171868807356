/**
 * Permissions index file
 *
 * @author: exode <hello@exode.ru>
 */

import { userManage } from '@/types/manage/permission/administrate-user';
import { getGlobalManagerPermissions } from '@/types/manage/permission/manager-global';
import { getProductManagerPermissions } from '@/types/manage/permission/manager-product';


export {
    userManage,
    getGlobalManagerPermissions,
    getProductManagerPermissions,
};

export const getSellerManagerPermissions = () => [
    ...getGlobalManagerPermissions(),
    ...getProductManagerPermissions(),
];
