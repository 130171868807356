/**
 * FileRow component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { IS_NATIVE } from '@/root/src/env';

import { useDialog } from '@/hooks/core';
import { If, useRedirect } from '@/cutils';
import { FileTypeByMime, FileUtil } from '@/utils';
import { ChatService } from '@/services/Chat/Chat';

import { Icon40DocumentCircle } from '@vkontakte/icons';
import { Avatar, Separator, SimpleCell, Text } from '@exode.ru/vkui';

import { PdfViewer } from '@/components/Atoms/PdfViewer';

import { Container } from './FileRow.styled';


export interface FileRowProps {
    name: string;
    location: string;
    mimeType: string;
    size?: number;
    createdAt?: Date;
    after?: ReactNode;
    dataTest?: string;
    className?: string;
    separator?: boolean;
    onClick?: (e: MouseEvent) => void;
}


const FileRow = (props: FileRowProps) => {

    const {
        name,
        size,
        after,
        onClick,
        location,
        mimeType,
        dataTest,
        createdAt,
        className,
        separator = false,
    } = props;

    const { redirect } = useRedirect();

    const fileType = FileUtil.getFileTypeByMimeType(mimeType);

    /** Image icon for preview of file */
    const avatarUrl = fileType === FileTypeByMime.Image ? location : '';

    /** Will not open in new tab */
    const inAppViewFileTypes = [
        FileTypeByMime.Image,
    ];

    const {
        openDialog: openPdfPreview,
        handleModalClose: closePdfPreview,
    } = useDialog({
        mode: 'mui',
        children: <PdfViewer url={location} title={name} onClose={() => closePdfPreview()}/>,
    });

    return (
        <>
            <Container className="w-full">
                <SimpleCell after={after}
                            data-test={dataTest}
                            className={[ '!pt-0 !pb-0 !pl-2 !pr-1', className ].join(' ')}
                            onClick={(e) => {
                                onClick?.(e as unknown as MouseEvent);

                                if (!inAppViewFileTypes.includes(fileType)) {
                                    if (!IS_NATIVE && mimeType.includes(('application/pdf'))) {
                                        return openPdfPreview();
                                    }

                                    redirect(location, true, true);
                                }
                            }}
                            subtitle={(
                                <Text className="text-subhead fs-12 mt-[-4px]">
                                    {[
                                        size && FileUtil.getFileSize(size),
                                        createdAt && ChatService.parseChatDate(createdAt),
                                    ].filter(e => e).join(' • ')}
                                </Text>
                            )}
                            before={(
                                <Avatar mode="app" size={32} src={avatarUrl} className={avatarUrl && 'thin-border'}>
                                    <If is={!avatarUrl}>
                                        <Icon40DocumentCircle width={30} height={30} fill="var(--accent)"/>
                                    </If>
                                </Avatar>
                            )}>
                    <Text weight="3" className="text-accent line-clamp-1 fs-13">
                        {name}
                    </Text>
                </SimpleCell>
            </Container>

            <If is={separator}>
                <Separator className="ml-[38px]"/>
            </If>
        </>
    );
};


export { FileRow };
