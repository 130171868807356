/**
 * RightPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/user/profile';

import { Auth, Link, Unauth } from '@/cutils';

import { Avatar, Search } from '@exode.ru/vkui';
import { Icon28UserCircleOutline } from '@vkontakte/icons';

import { HeaderItem } from '@/components/Desktop/Panel';
import { DesktopNavigationContextMenuDrawer } from '@/components/Desktop/Navigation';

import { LanguageDropdownPart } from './LanguageDropdownPart';
import { SettingsDropdownPart } from './SettingsDropdownPart';


const RightPart = observer(() => {
    return (
        <div className="viewport__right-menu vkuiSplitCol--spaced h-full">
            <div className="h-full flex items-center justify-between">
                <Link pushPage={{ id: '/friends' }}>
                    <Search after="" style={{ width: '100%' }}/>
                </Link>

                <Auth>
                    <SettingsDropdownPart/>
                </Auth>

                <Unauth>
                    <LanguageDropdownPart component={HeaderItem} componentProps={{
                        className: '!rounded-none ml-1',
                    }}/>

                    <Link pushPage={{ id: '/login' }}>
                        <HeaderItem className="bg-transparent ml-1">
                            <Avatar size={35} shadow={false}>
                                <Icon28UserCircleOutline className="text-accent"/>
                            </Avatar>
                        </HeaderItem>
                    </Link>
                </Unauth>

                <DesktopNavigationContextMenuDrawer/>
            </div>
        </div>
    );
});


export { RightPart };
