/**
 * SchoolService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import i18n from 'i18next';

import { GqlResult } from '@/types/graphql';
import { AppColorScheme } from '@/shared/types';

import { apolloClient } from '@/api/graphql';
import { SchoolStore } from '@/store/platform';
import { PreferenceStore } from '@/store/preference/preference';

import { BaseService } from '@/services/Core/Base';

import { DocumentEvent } from '@/types/window';
import { getColorVariables } from '@/pages/Manage/School/constants';
import { SchoolColorVariablesPreference, SchoolFindOneDocument, SchoolFindOneQueryResult } from '@/codegen/graphql';


class SchoolService extends BaseService {

    /**
     * Регистрация сервиса
     */
    static register() {
        document.addEventListener(
            DocumentEvent.PreferenceSchemeChanged,
            () => this.applyColorVariables(SchoolStore?.preferences?.colorVariables),
        );
    }

    /**
     * Инициализация сервиса
     */
    static init() {
        this.applyColorVariables(SchoolStore?.preferences?.colorVariables);
    }

    /**
     * Refetch school
     */
    static async refetchSchool() {
        try {
            const {
                data,
                error,
            } = await apolloClient.query<GqlResult<SchoolFindOneQueryResult>>({
                query: SchoolFindOneDocument,
                variables: { schoolId: SchoolStore.schoolId },
            });

            const school = data.schoolFindOne;

            if (error || !school) {
                return null;
            }

            SchoolStore.merge({ school });

            return school;
        } catch (e) {
            return null;
        }
    }

    /**
     * Change color variables
     * @param {} colorVariables
     */
    static applyColorVariables(
        colorVariables: SchoolColorVariablesPreference | undefined | null,
    ) {
        if (_.isEmpty(colorVariables)) {
            return;
        }

        const allColorVariables = getColorVariables(i18n.t);

        const currentScheme = PreferenceStore.isDark
            ? AppColorScheme.SpaceGray
            : AppColorScheme.BrightLight;

        const themeColorVariables = colorVariables?.[currentScheme];

        for (const [ __, { name, defaultValue } ] of _.entries(allColorVariables)) {
            const value = themeColorVariables?.[name] || defaultValue?.[currentScheme];

            if (value) {
                document.querySelector('body')?.style.setProperty(name, value);
            }
        }
    }

}


export { SchoolService };
