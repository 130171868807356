/**
 * ContentIconsPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, ShopStore } from '@/store/user/shop';
import { PreferenceStore } from '@/store/preference/preference';

import { Auth, Graphql, If, Link, Platform, Responsive } from '@/cutils';

import { Avatar, Counter, TabbarItem } from '@exode.ru/vkui';
import { Icon28MoonOutline, Icon28ShoppingCartOutline, Icon28SunOutline } from '@vkontakte/icons';

import { PageSpace } from '@/shared/types';
import { NotificationCountByStatusQuery, NotificationStatus } from '@/codegen/graphql';

import { HeaderItem } from '@/components/Desktop/Panel';
import { PreferenceThemeToggle } from '@/components/Preference/Theme';

import { NotificationHeaderItemPart } from '../parts/NotificationHeaderItem';


const ContentIconsPart = observer(() => {
    return (
        <>
            <Platform.If platforms={[
                PageSpace.SchoolPlatform,
                PageSpace.MarketplacePlatform,
            ]}>
                <If is={!!ShopStore.cartLength}>
                    <Link pushPage={{ id: '/cart' }}>
                        <HeaderItem className="bg-transparent">
                            <TabbarItem indicator={(
                                <Counter mode="prominent" size="s">
                                    {ShopStore.cartLength}
                                </Counter>
                            )}>
                                <Icon28ShoppingCartOutline fill="var(--accent)"/>
                            </TabbarItem>
                        </HeaderItem>
                    </Link>
                </If>

                <Auth>
                    <NotificationCountByStatusQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <NotificationHeaderItemPart/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ notificationCountByStatus: count }) => (
                                    <NotificationHeaderItemPart count={count}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}>
                                <NotificationHeaderItemPart/>
                            </Graphql.Error>
                        </>
                    )} variables={{ notificationStatus: NotificationStatus.Unread }}/>
                </Auth>
            </Platform.If>

            <Responsive.DesktopOnly>
                <If is={!PreferenceStore.toggleIsDisabled}>
                    <PreferenceThemeToggle>
                        <HeaderItem className="bg-transparent">
                            <Avatar size={35} shadow={false}>
                                <If is={PreferenceStore.isDark}>
                                    <Icon28SunOutline className="text-accent"/>
                                </If>

                                <If is={!PreferenceStore.isDark}>
                                    <Icon28MoonOutline className="text-accent"/>
                                </If>
                            </Avatar>
                        </HeaderItem>
                    </PreferenceThemeToggle>
                </If>
            </Responsive.DesktopOnly>
        </>
    );
});


export { ContentIconsPart };
