/**
 * SettingsDropdownParts
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { IS_BIZ_WELCOME } from '@/root/src/env';

import { ProfileStore } from '@/store/user/profile';
import { observer, UserAuthStore } from '@/store/user/auth';

import { PageSpace } from '@/shared/types';
import { Graphql, Link, Platform } from '@/cutils';
import { useConfirm, useI18n } from '@/hooks/core';

import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { CellButton, Separator, SimpleCell } from '@exode.ru/vkui';

import {
    Icon20GearCircleFillGray,
    Icon24DoorArrowRightOutline,
    Icon24GearOutline,
    Icon24UsersOutline,
} from '@vkontakte/icons';

import { GetUserProfileQuery } from '@/codegen/graphql';

import { HeaderItem } from '@/components/Desktop/Panel';
import { UserAvatar } from '@/components/Atoms/UserAvatar';


const SettingsDropdownPart = observer(() => {

    const [ show, setShow ] = useState(false);

    const { t } = useI18n('components.Desktop.Panel.Header');

    const { user, avatar, fullName } = ProfileStore;

    const commonUserCellProps = {
        size: 34,
        showUserOnlineBadge: false,
        dataTest: 'header.rightAvatar',
        badge: IS_BIZ_WELCOME
            ? undefined
            : <Icon20GearCircleFillGray width={16} height={16}/>,
    };

    const { openConfirm: openConfirmLogout } = useConfirm({
        title: t('logoutFromAccount'),
        subtitle: t('logoutConfirm'),
        onConfirm: () => UserAuthStore.logout(),
        buttons: {
            confirm: { text: t('logout') },
        },
    });

    return (
        <Dropdown shown={show}
                  action="click"
                  offsetSkidding={48}
                  offsetDistance={-60}
                  placement="left-end"
                  onShownChange={setShow}
                  className="overflow-hidden"
                  content={(
                      <div onClick={() => setShow(false)}>
                          <Platform.If platforms={[
                              PageSpace.SchoolPlatform,
                              PageSpace.MarketplacePlatform,
                          ]}>
                              <Link pushPage={{ id: '/settings' }}>
                                  <SimpleCell className="!rounded-none"
                                              before={<Icon24GearOutline/>}
                                              subtitle={t('accountSettings')}>
                                      {t('settings')}
                                  </SimpleCell>
                              </Link>
                          </Platform.If>

                          <Link pushModal={{ id: 'user-invite' }}>
                              <SimpleCell className="!rounded-none"
                                          before={<Icon24UsersOutline/>}
                                          subtitle={t('bonusesForEveryFriend')}>
                                  {t('inviteFriends')}
                              </SimpleCell>
                          </Link>

                          <Separator wide/>

                          <CellButton mode="danger"
                                      data-test="header.logout"
                                      className="!rounded-none"
                                      subtitle={t('endCurrentSession')}
                                      onClick={() => openConfirmLogout()}
                                      before={<Icon24DoorArrowRightOutline/>}>
                              {t('logout')}
                          </CellButton>
                      </div>
                  )}>
            <div>
                <HeaderItem className="ml-1">
                    <GetUserProfileQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <UserAvatar name={fullName}
                                            userId={user?.id}
                                            src={avatar?.medium || ''} {...commonUserCellProps}/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ profileFindOne: profile }) => (
                                    <UserAvatar name={profile?.fullName || ''}
                                                userId={profile?.user?.id || 0}
                                                src={profile?.avatar?.medium || avatar?.medium || ''}
                                                {...commonUserCellProps}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}>
                                <UserAvatar name={fullName}
                                            userId={user?.id}
                                            src={avatar?.medium || ''} {...commonUserCellProps}/>

                            </Graphql.Error>
                        </>
                    )} variables={{ userId: ProfileStore?.user?.id }}/>
                </HeaderItem>
            </div>
        </Dropdown>
    );
});


export { SettingsDropdownPart };
