/**
 * Socket.io
 *
 * @author: exode <hello@exode.ru>
 */

import socketIOClient from 'socket.io-client';

import { wsLink } from '@/api/graphql';
import { wsUrl } from '@/api/constants';
import { DocumentEvent } from '@/types/window';
import { basicRequestHeaders } from '@/api/headers';
import { ConfigStore } from '@/store/core/config';


export const socket = socketIOClient(wsUrl, {
    auth: basicRequestHeaders(),
    transports: [ 'websocket' ],
});

export const reconnectSocket = () => {
    socket.auth = basicRequestHeaders();
    socket.disconnect().connect();
    wsLink.getClient.restart();
};

/** Disconnection state */
const state = {
    disconnected: false,
};

socket.on('connect', () => {
    console.log('SocketIO: connected');

    document.dispatchEvent(new CustomEvent(DocumentEvent.WsConnected));

    if (state.disconnected && ConfigStore.disconnected) {
        ConfigStore.merge({ disconnected: false });

        document.dispatchEvent(new CustomEvent(DocumentEvent.WsConnectedAfterDisconnect));
    }
});

socket.on('disconnect', () => {
    console.log('SocketIO: disconnected');
    state.disconnected = true;
    ConfigStore.merge({ disconnected: true });

    document.dispatchEvent(new CustomEvent(DocumentEvent.WsDisconnected));
});

socket.on('error', (msg: string) => {
    console.error('SocketIO: error', msg);
});
